import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/utils/request';
import { getToken } from '@/utils/auth';
export default {
  name: 'Index',
  data: function data() {
    return {
      BASE_API: process.env.VUE_APP_BASE_API,
      myHeaders: {
        'X-Auth-Token': getToken()
      },
      uploadFile: null,
      fileList: [],
      excelTemplate: null,
      uploadFileMetadata: null,
      sheetIndex: 0,
      loading: false,
      importFileLoading: false,
      downloadLoading: false
    };
  },
  computed: {
    pleaseSelect: function pleaseSelect() {
      return this.$t('importFile.pleaseSelect');
    },
    templateRequest: function templateRequest() {
      return {
        templateRequest: JSON.stringify({
          moduleName: this.$route.query.module_name
        })
      };
    },
    action: function action() {
      return this.BASE_API + 'import-data/excel-file-metadata';
    },
    mapBusinessTableHeaderOptions: function mapBusinessTableHeaderOptions() {
      var templatesColumnList = this.getTemplatesColumnList();
      return [{
        description: this.pleaseSelect,
        excelColumnIndex: -1
      }].concat(templatesColumnList.filter(function (v) {
        return v.excelColumnIndex == -1;
      }));
    }
  },
  created: function created() {
    var module_name = this.$route.query.module_name;
    this.getExcelTemplate(module_name);
  },
  methods: {
    onTabClick: function onTabClick() {},
    formatter: function formatter(row, column, cellValue, index) {
      return row[column.index];
    },
    getExcelTemplate: function getExcelTemplate() {
      var _this = this;
      request({
        url: 'data-template',
        method: 'post',
        data: {
          templateId: this.$route.query.id,
          moduleName: this.$route.query.module_name
        }
      }).then(function (response) {
        if (response.status === 200) {
          var code = response.data.code;
          if (!isNull(code)) {
            _this.$message({
              message: response.data.message,
              type: 'warning',
              duration: 5000,
              offset: 300
            });
          } else {
            _this.excelTemplate = response.data;
          }
        }
      });
    },
    initExcelColumnIndex: function initExcelColumnIndex() {
      var excelTemplate = this.excelTemplate;
      var templateSheets = excelTemplate.templateSheets;
      var uploadFileMetadata = this.uploadFileMetadata;
      var header_obj = {};
      for (var i = 0, length = uploadFileMetadata.length; i < length; i++) {
        var header = uploadFileMetadata[i].header;
        var obj = {};
        for (var k = 0, size = header.length; k < size; k++) {
          var key = trim(header[k]);
          key = key.replace(/[ ]*▼$/img, '');
          obj[key] = k;
        }
        header_obj[i] = obj;
      }
      for (var _i = 0, _length = templateSheets.length; _i < _length; _i++) {
        var templateColumnList = templateSheets[_i].templateColumnList;
        for (var j = 0, _size = templateColumnList.length; j < _size; j++) {
          var desc = templateColumnList[j].description;
          desc = trim(desc);
          var excelColumnIndex = header_obj[_i][desc];
          if (isNull(excelColumnIndex)) {
            excelColumnIndex = -1;
          }
          templateColumnList[j].excelColumnIndex = excelColumnIndex;
        }
      }
    },
    exportExcel: function exportExcel() {
      var _this2 = this;
      this.downloadLoading = true;
      request({
        url: 'import-data/download-excel-template',
        method: 'post',
        headers: {
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
        data: {
          templateId: this.$route.query.id,
          moduleName: this.$route.query.module_name
        },
        attachmentFileName: this.excelTemplate.name
      }).then(function (response) {
        _this2.downloadLoading = false;
      });
    },
    submit: function submit() {
      var _this3 = this;
      var __this = this;
      var uploadFile = this.uploadFile;
      this.loading = true;
      var formData = new FormData();
      formData.append('excelTemplate', JSON.stringify(this.excelTemplate));
      if (this.uploadDestFile) {
        formData.append('attachmentFile', JSON.stringify({
          destName: this.uploadDestFile,
          name: uploadFile.raw.name,
          size: uploadFile.raw.size,
          type: uploadFile.raw.type
        }));
      } else {
        formData.append('file', uploadFile.raw);
      }
      request({
        url: 'import-data/excel',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
        data: formData,
        callBack: function callBack() {
          return true;
        }
      }).then(function (res) {
        _this3.loading = false;
        if (res.status === 200) {
          var headers = res.headers;
          if (headers['content-type'].startsWith('application/vnd')) {
            _this3.$confirm(_this3.$t('importFile.importFail'), _this3.$t('importFile.confirm.title'), {
              confirmButtonText: _this3.$t('importFile.confirm.confirmButtonSee'),
              showCancelButton: false,
              type: 'error'
            }).then(function () {
              //excel file
              var uploadFileName = uploadFile.name;
              if (isNull(uploadFileName)) {
                uploadFileName = 'data-template.xls';
              }
              var blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel'
              });
              var a = document.createElement('a');
              var blobURL = URL.createObjectURL(blob);
              a.download = _this3.uploadFile.name;
              a.download = uploadFileName;
              a.href = blobURL;
              a.type = 'application/vnd.ms-excel';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }).catch(function () {});
          } else {
            ab2string(res.data, function (data) {
              data = JSON.parse(data);
              if (data.code != '0') {
                if (data.code == '408') {
                  var __key = __this.$t(data.data.key);
                  var __value = data.data.value;
                  var __content = __key.format(__value);
                  __this.$confirm(__content, __this.$t('importFile.confirm.title'), {
                    confirmButtonText: __this.$t('importFile.confirm.confirmButton'),
                    cancelButtonText: __this.$t('importFile.confirm.cancelButton'),
                    showCancelButton: true,
                    type: 'warning'
                  }).then(function () {
                    __this.$router.back();
                  }).catch(function () {
                    __this.$router.push('/sys/export-file/list');
                  });
                } else {
                  __this.$message({
                    message: data.message,
                    type: 'error'
                  });
                }
                return;
              } else {
                __this.$message({
                  message: __this.$t('importFile.importSuccess'),
                  type: 'success'
                });
                __this.$router.back();
              }
            });
          }
        }
      });
    },
    onSelectFile: function onSelectFile() {},
    selectUploadFile: function selectUploadFile() {
      this.$refs.upload.click();
    },
    onChange: function onChange(file, fileList) {
      console.log('-----onChange-----');
      console.log(file);
      console.log(fileList);
    },
    onError: function onError(err, file, fileList) {
      this.importFileLoading = false;
    },
    onSuccess: function onSuccess(response, file, fileList) {
      this.importFileLoading = false;
      console.log('-----onSuccess-----');
      if (response.code != '0') {
        var message = response.message;
        if (isNull(message)) {
          message = response;
        }
        this.$message.warning(message);
        return;
      }
      this.uploadFileMetadata = response.data.metadata;
      this.uploadDestFile = response.data.file_name;
      this.uploadFile = file;
      this.initExcelColumnIndex();
    },
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    },
    beforeUpload: function beforeUpload(file) {
      this.importFileLoading = true;
      console.log(file);
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    getTemplatesColumnList: function getTemplatesColumnList() {
      try {
        var templateColumnList = this.excelTemplate.templateSheets[parseInt(this.sheetIndex)].templateColumnList;
        return templateColumnList;
      } catch (e) {}
      return [];
    },
    getSelectValue: function getSelectValue(index, item) {
      var templatesColumnList = this.getTemplatesColumnList();
      for (var i = 0, length = templatesColumnList.length; i < length; i++) {
        if (templatesColumnList[i].excelColumnIndex == index) {
          return templatesColumnList[i].description;
        }
      }
      return this.pleaseSelect;
    },
    onSelectChange: function onSelectChange(ufm_item, index, option) {
      var templatesColumnList = this.getTemplatesColumnList();
      for (var i = 0, length = templatesColumnList.length; i < length; i++) {
        if (templatesColumnList[i].excelColumnIndex == index) {
          templatesColumnList[i].excelColumnIndex = -1;
        }
      }
      if (option.excelColumnIndex == -1) {
        option.excelColumnIndex = index;
      }
    }
  }
};